<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="row" v-if="isLoad">
                    <div class="col-12 text-center">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12">
                        <h1 class="mt-4 font-weight-bolder"><i class="fas fa-user-cog mr-3"></i>Privilege</h1><hr>
                        <div class="row justify-content-center d-flex" v-if="roles.length">
                            <div  class="col-lg-10">
                                <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
                            </div>
                            <div  class="col-lg-2">
                                <router-link :to="{name: 'PrivillageCreateAdmin'}" class="btn btn-current btn-block btn-lg"><i class="fas fa-plus"></i></router-link>
                            </div>
                            <div class="col-xl-12 col-lg-12 text-center py-3">
                                <div class="table-responsive mw-100 px-1">
                                    <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="roles" :page="1" :perPage="10">
                                        <template name="default" slot-scope="{ row, index }">
                                            <tr>
                                                <td>{{ index+1 }}</td>
                                                <td>{{ row.name }}</td>
                                                <td v-if="(row.id !== 1 && row.id !== 2)">
                                                    <router-link :to="{name: 'PrivillagePermissionAdmin', params: {idRole: row.id}}" class="btn btn-sm btn-info mx-1"><i class="fas text-white fa-tasks"></i></router-link>
                                                    <router-link :to="{name: 'PrivillageEditAdmin', params: {idRole: row.id}}" class="btn btn-sm btn-warning mx-1"><i class="fas text-white fa-edit"></i></router-link>
                                                    <a href="javascript:void(0)" @click="deleteAlert(row.id)" class="btn btn-sm btn-danger mx-1"><i class="fas text-white fa-trash"></i></a>
                                                </td>
                                                <td v-else>
                                                    Default
                                                </td>
                                            </tr>
                                        </template>
                                        <template name="no-result">
                                            Nothing to see here
                                        </template>
                                    </datatable>
                                </div>
                                <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-12 text-center py-3"> 
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "Privillage",
    data(){
        return {
            isLoad: true,
            roles: [],
            tableFilter: '',
            columns: [
                {label: 'No', field: 'name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail wide-column'},
                {label: 'Name', field: 'name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail wide-column'},
                {label: 'Action', field: 'name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail wide-column'},
            ],
            page: 1
        }
    },

    created(){
        this.getRoles()
    },
    methods:{
        async getRoles() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/role`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.roles = res.data
                this.isLoad = false
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    this.deleteRole(id)
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.getRoles()
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Privilege',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deleteRole(id){
            var data = {
                id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/role`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.status == 200){
                    this.$swal({
                        toast: true,
                        title: 'Privilege!',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch((err) => {
                console.log(err)
            })
        },
    }

}
</script>
